import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import {IFooterData} from "../../../lib/types/common";
import {dynamicCssSize} from "../../../lib/utils/constants/PagesConstants";
import {footerLinkComponentUI} from "./FooterComponentV2";

const FooterComponentV2Desktop = (footerData: IFooterData) => {
  return (
      <>
          <style jsx>{`
            .allHeadingsV2 {
              font-size: ${dynamicCssSize(25, 14)};
              font-weight: 500;
              text-transform: uppercase;
              line-height: 1.25;
              letter-spacing: -0.14px;
              text-align: left;
              color: rgba(216, 216, 216, 0.74);
              margin: 25px 0 13px;
            }

            .linksCol {
              font-size: ${dynamicCssSize(20, 12)};
              font-weight: 400;
              line-height: 1.25;
              letter-spacing: -0.11px;
              color: #d8d8d8;
              margin-bottom: 5px;
            }

            .linksCol a {
              text-decoration: none;
              color: #d8d8d8;
            }

            @media screen and (max-width: 767px) {
              .footerTopLeftV2 {
                display: none;
              }

              .linksCol {
                padding-right: 5px;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.85);
              }

            }
          `}</style>
          <div className={"col-md-7 col-12 footerTopLeftV2"}>
              <div className={"gamesSectionV2"}>
                  <div className={"row"}>
                      <div className={"col"}>
                          <p className={"allHeadingsV2"}>
                              {footerData.gamesHeading}
                          </p>
                      </div>
                  </div>
                  {footerData.gamesSections && <div className={"row"}>{
                      footerData.gamesSections.map((item, index) => {
                          return (
                              <div className="linksCol col-4" key={"footerData_gamesSections_" + index}>
                                  <CustomLinkComponent
                                      href={item.path}
                                      mobStyle={{
                                          color: "#d8d8d8",
                                          textDecoration: "none"
                                      }}
                                      desktopStyle={{
                                          color: "#d8d8d8",
                                          textDecoration: "none"
                                      }}
                                  >
                                      {item.text}
                                  </CustomLinkComponent>
                              </div>
                          )
                      })
                  }</div>}
              </div>

              <div className={"blogSectionV2"}>
                  <div className={"row"}>
                      <div className={"col"}>
                          <p className={"allHeadingsV2"}>
                              {footerData.blogHeading}
                          </p>
                      </div>
                  </div>
                  {footerData.blogSections && <div className={"row"}>{
                      footerData.blogSections.map((item, index) => {
                          return (
                              <div className="linksCol col-4" key={"footerData_blogSections_" + index}>
                                  <a href={item.path}>
                                      {item.text}
                                  </a>
                              </div>
                          )
                      })
                  }</div>}
              </div>

              <div className={"policiesSectionV2"} id="footer_policies_section">{
                  footerData.policiesHeading &&
                  <>    
                      <div className={"row"}>
                          <div className={"col"}>
                              <p className={"allHeadingsV2"}>
                                  {footerData.policiesHeading}
                              </p>
                          </div>
                      </div>
                      <div className={"row"}>{
                          footerData.policiesSections.map((item, index) => {
                              return (
                                  <div className="linksCol col-4" key={"footerData_policiesSections_" + index}>
                                      <a href={item.path}>
                                          {item.text}
                                      </a>
                                  </div>
                              )
                          })
                      }</div>
                  </>
              }</div>

              <div className={"aboutUsSectionV2"} id="footer_aboutus_section">
                  <div className={"row"}>
                      <div className={"col"}>
                          <p className={"allHeadingsV2"}>
                              {footerData.footerHeading}
                          </p>
                      </div>
                  </div>
                  <div className={"row"}>{
                      footerData.footerSections.map((item, index) => {
                          let key = item.key;
                          return (
                              <div className="linksCol col-4" key={"footerData_footerSections_" + index}>
                                  {footerLinkComponentUI(key, index, item, footerData.downloadLink)}
                              </div>
                          )
                      })
                  }</div>
              </div>
          </div>
      </>
  )
}

export default FooterComponentV2Desktop