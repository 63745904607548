import HTMLReactParser from "html-react-parser";
import Analytics from "../../../lib/analytics/Analytics";
import { CareerPageClick } from "../../../lib/analytics/AnalyticsEvents";
import { IFooterComponentProps } from "../../../lib/types/components";
import {
    BUG_BOUNTY,
    CAREER_KEY,
    DOWNLOAD_KEY,
    GET_APP_AF_URL,
    GET_APP_URL
} from "../../../lib/utils/constants/GlobalConstants";
import {
    currentPageURL,
    dynamicCssSize,
    handleFooterDownloadClick,
    isMobile
} from "../../../lib/utils/constants/PagesConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import FooterComponentV2Desktop from "./FooterComponentV2Desktop";

export const footerLinkComponentUI = (key, index, item, downloadLink) => {

    return (
        <>
            <style jsx>{`
                  a {
                    color: #d8d8d8;
                    text-decoration: none;
                  }
                  a:hover {
                    text-decoration: none;
                    color: #d8d8d8;
                  }
                `}</style>
            {
                key === CAREER_KEY ?
                    <a href={item.path} onClick={() => {
                        Analytics.getInstance().logEvent(CareerPageClick);
                    }} className={"link"}>
                        {item.text}
                    </a> :
                    key === DOWNLOAD_KEY ?
                        <CustomLinkComponent
                            href={downloadLink}
                            mobStyle={{
                                color: "#d8d8d8",
                                textDecoration: "none"
                            }}
                            desktopStyle={{
                                color: "#d8d8d8",
                                textDecoration: "none"
                            }}
                        >
                                <span onClick={() => handleFooterDownloadClick()}>
                                    {item.text}
                                </span>
                        </CustomLinkComponent>
                        : key === BUG_BOUNTY ?
                            <a href={item.path}>
                                {item.text}
                            </a>
                            :
                            <CustomLinkComponent
                                href={item.path}
                                mobStyle={{
                                    color: "#d8d8d8",
                                    textDecoration: "none"
                                }}
                                desktopStyle={{
                                    color: "#d8d8d8",
                                    textDecoration: "none"
                                }}
                            >
                                {item.text}
                            </CustomLinkComponent>
            }
        </>
    )
}

const FooterComponentV2 = (props: IFooterComponentProps) => {

    const {
        footerData,
        downloadLink,
        membersData,
        withdrawalPartnerData,
        isUtmHotstar
    } = props;

    return (
        footerData ?
            <>
                <style jsx>{`
                  .footerContainerV2 {
                    font-family: var(--font-primary);
                    background-color: #1b1b1b;
                    color: white;
                    border-top: 2px solid white;
                    padding-top: ${dynamicCssSize(60, 25)};
                  }

                  .allHeadingsV2 {
                    font-size: ${dynamicCssSize(25, 14)};
                    font-weight: 500;
                    text-transform: uppercase;
                    line-height: 1.25;
                    letter-spacing: -0.14px;
                    text-align: left;
                    color: rgba(216, 216, 216, 0.74);
                    margin: 25px 0 13px;
                  }

                  .linksCol {
                    font-size: ${dynamicCssSize(20, 12)};
                    font-weight: 400;
                    line-height: 1.25;
                    letter-spacing: -0.11px;
                    color: #d8d8d8;
                    margin-bottom: 5px;
                  }

                  .linksCol a {
                    text-decoration: none;
                    color: #d8d8d8;
                  }

                  .footerTopRight {
                    margin-top: 25px;
                  }

                  .footerLinks a {
                    color: #d8d8d8;
                  }

                  .headingMobile {
                    display: none;
                  }

                  .winzoFooterImg {
                    width: 200px;
                    height: 60px;
                    position: relative;
                    margin-left: -10px;
                    cursor: pointer;
                  }

                  .socialFooterContainer {
                    display: flex;
                    flex-direction: row;
                    margin-top: 15px;
                  }

                  .socialImageContainer {
                    width: ${dynamicCssSize(50, 36)};
                    height: ${dynamicCssSize(50, 36)};
                    position: relative;
                    margin-right: 10px;
                  }

                  .membersDataContainer {
                    margin: ${dynamicCssSize(80, 20)} 0 ${dynamicCssSize(30, 20)};
                  }

                  .membersDataHeading {
                    font-size: ${dynamicCssSize(22, 14)};
                    font-weight: 500;
                    letter-spacing: -0.13px;
                    color: #fff;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                  }

                  .paymentContainer {
                    display: flex;
                    flex-direction: row;
                    height: 30px;
                    align-items: center;
                  }

                  .paymentContainerDesktop {
                    display: flex;
                  }

                  .paymentContainerMobile {
                    display: none;
                  }

                  .colMobile {
                    display: none;
                  }

                  .disclaimerContainer {
                    margin-top: ${dynamicCssSize(55, 30)};
                  }

                  .disclaimerHeading {
                    text-transform: uppercase;
                    font-size: ${dynamicCssSize(18, 14)};
                    font-weight: 500;
                    letter-spacing: -0.1px;
                  }


                  .disclaimerText {
                    opacity: 0.7;
                    font-size: ${dynamicCssSize(18, 12)};
                    letter-spacing: -0.22px;
                    color: #fff;
                  }


                  .copyright {
                    opacity: 0.7;
                    font-size: 12px;
                    letter-spacing: -0.08px;
                  }


                  .memberContainer {
                    display: flex;
                    align-items: center;
                  }

                  .memberContainer > div:nth-child(1) {
                    margin-right: 20px;
                  }

                  .copyright {
                    text-align: center;
                    padding: 15px 0;
                  }

                  .lineSeparator, .biggerLineSeparator {
                    display: none;
                  }

                  @media screen and (max-width: 767px) {
                    .footerTop {
                      text-align: center;
                    }

                    .footerTopRight {
                      margin-top: 0;
                    }

                    .headingMobile {
                      display: block;
                      font-size: 14px;
                      font-weight: 500;
                      letter-spacing: -0.22px;
                      margin-bottom: 16px;
                      text-transform: uppercase;
                    }

                    .winzoFooterImg {
                      display: none;
                    }

                    .socialFooterContainer {
                      justify-content: space-around;
                      align-items: center;
                      margin-bottom: 24px;
                    }

                    .socialFooterContainer > a {
                      margin-right: 0;
                    }

                    .membersDataHeading {
                      font-size: 14px;
                      margin-bottom: 15px;
                    }

                    .memberContainer {
                      justify-content: center;
                    }


                    .lineSeparator, .biggerLineSeparator {
                      display: block;
                      border: 1px solid #979797;
                      opacity: 0.3;
                      width: 30%;
                    }

                    .biggerLineSeparator {
                      width: 100%;
                    }

                    .colMobile {
                      margin-top: 15px;
                      display: flex;
                      justify-content: center;
                      text-align: left;
                      flex-direction: column;
                    }

                    .allHeadingsV2, .disclaimerHeading {
                      font-weight: 600;
                      text-align: center;
                      margin: 0px 0px 15px;
                      color: #ffffff;
                    }

                    .linksCol {
                      padding-right: 5px;
                      font-weight: 500;
                      color: rgba(255, 255, 255, 0.85);
                    }

                    .paymentContainer {
                      justify-content: center;
                    }

                    .paymentContainerDesktop {
                      display: none;
                    }

                    .paymentContainerMobile {
                      display: flex;
                      position: relative;
                      justify-content: center;
                      height: 22px;
                    }

                    .disclaimerText {
                      color: rgba(255, 255, 255, 0.85);
                    }

                    .copyright {
                      font-size: 12px;
                      padding: 15px;
                    }
                  }
                `}</style>

                <div className="container-fluid footerContainerV2">
                    <div className="container">
                        <div className="footerTop row">
                            {<FooterComponentV2Desktop {...footerData} downloadLink={downloadLink}/>}

                            <div className={"footerTopRight col-md-5 col-12"}>
                                <div className="footerNavs">
                                    <h2 className="headingMobile">{footerData.mobileText}</h2>
                                    <div className="winzoFooterImg">
                                        <CustomLinkComponent href="/">
                                            <CustomImageComponent
                                                layout={"fill"}
                                                objectFit={"contain"}
                                                src={footerData.winzoLogo}
                                                alt="winzo games logo"
                                            />
                                        </CustomLinkComponent>
                                    </div>
                                    <div className="socialFooterContainer">
                                        {
                                            footerData.socialMedia.map((item, index) => {
                                                let id = item.link.replace(/[^\w\s]/gi, '_')
                                                return (
                                                    <a id={id} target="_blank" key={"footerData_socialMedia_" + index} href={item.link}
                                                       rel={"noreferrer"} className={"socialImageContainer"}>
                                                        <CustomImageComponent
                                                            layout={"fill"}
                                                            objectFit={"contain"}
                                                            src={item.src}
                                                            alt={"social-media-image"}
                                                        />
                                                    </a>
                                                )
                                            })
                                        }
                                    </div>

                                    {membersData && <div className={"membersDataContainer"}>
                                        <h3 className={"membersDataHeading"}>{membersData.heading}</h3>
                                        <div className="memberContainer">
                                            {
                                                membersData.list.map((item, index) => {
                                                    return (
                                                        <div className={"memberImageContainer"} key={"membersData_list_" + index}>
                                                            <CustomImageComponent
                                                                key={index}
                                                                width={80}
                                                                height={55}
                                                                src={item.src}
                                                                alt={item.alt}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>}
                                    <div className={"ourPartnerContainer"}>
                                        {withdrawalPartnerData &&
                                            <p className={"membersDataHeading"}>
                                                {
                                                    (currentPageURL === GET_APP_URL) && isUtmHotstar
                                                        ? footerData.ourPartnersText
                                                        : footerData.paymentPartnersText
                                                }
                                            </p>
                                        }
                                        {withdrawalPartnerData && withdrawalPartnerData.footerImg && <div className="paymentContainer paymentContainerDesktop">
                                            <CustomImageComponent
                                                width={300}
                                                height={25}
                                                src={withdrawalPartnerData.footerImg.src}
                                                alt={withdrawalPartnerData.footerImg.alt}
                                            />
                                        </div>}
                                        {withdrawalPartnerData && withdrawalPartnerData.footerImgV2 && <div className="paymentContainer paymentContainerMobile">
                                            <CustomImageComponent
                                                layout={"fill"}
                                                objectFit={"contain"}
                                                src={withdrawalPartnerData.footerImgV2.src}
                                                alt={withdrawalPartnerData.footerImgV2.alt}
                                            />
                                        </div>}
                                    </div>
                                   {withdrawalPartnerData && <hr className={"lineSeparator"}></hr>}
                                    <div className={"colMobile"} id="footer_aboutus_section">
                                        <div className="row no-gutters">
                                            <p className={"col allHeadingsV2"}>
                                                {footerData.footerHeading}
                                            </p>
                                        </div>
                                        <div className={"row no-gutters"}>{
                                            footerData.footerSections.map((item, index) => {
                                                let key = item.key;
                                                return (
                                                    <div className={"linksCol col-6"} key={"footerData.footerSections_" + index}>
                                                        {footerLinkComponentUI(key, index, item, downloadLink)}
                                                    </div>
                                                )
                                            })
                                        }</div>
                                    </div>
                                    {withdrawalPartnerData && <hr className={"lineSeparator"}></hr>}
                                    {
                                        footerData.policiesHeading &&
                                        <div className={"colMobile"} id="footer_policies_section">
                                            <div className="row no-gutters">
                                                <p className={"col allHeadingsV2"}>
                                                    {footerData.policiesHeading}
                                                </p>
                                            </div>
                                            <div className={"row no-gutters"}>{
                                                footerData.policiesSections.map((item, index) => {
                                                    return (
                                                        <div className={"linksCol col-6"} key={"footerData.policiesSections_" + index}>
                                                            <a href={item.path} key={index}>
                                                                {item.text}
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            }</div>
                                        </div>
                                    }
                                    <hr className={"lineSeparator"}></hr>
                                    <div className="disclaimerContainer">
                                        <h3 className={"disclaimerHeading"}>{footerData.disclaimer.heading}</h3>
                                        <p className={"disclaimerText"}>{footerData.disclaimer.text} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className={"biggerLineSeparator"}></hr>
                        <div className="footerBottom row">
                            <div className="copyright col">
                                <p>
                                    <span>
                                        © {new Date().getFullYear()}{footerData.rightsReservedText}{" "}
                                    </span>
                                    {
                                        footerData.footerLinks.map((item, index) => {
                                            let id = item.path.replace(/[^\w\s]/gi, '_')
                                            return (
                                                <span  id={id} key={"footerData.footerLinks_" + index} className="footerLinks">
                                                    <CustomLinkComponent href={item.path}>{item.text}</CustomLinkComponent> - {" "}
                                                </span>
                                            )
                                        })
                                    }
                                    {
                                      footerData.linkText && <span>
                                        {HTMLReactParser(footerData.linkText)}
                                      </span>
                                    }
                                </p>
                                {
                                    currentPageURL === GET_APP_AF_URL && (
                                        <p>{footerData.addressText}</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
            :
            <></>
    )
}

export default FooterComponentV2;